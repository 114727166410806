<style>
.inherit-color {
  background-color: inherit !important;
  color: inherit !important;
}
</style>
<template>
  <div>
    <div class="text-light">
      <div class="row">
        <div class="col-md-8"></div>
        <div class="col-md-4">
          <h4 class="d-flex justify-content-between align-items-center mb-3">
            <span class="text-muted d-block d-sm-block d-md-none d-lg-none d-xl-none">Make Payment</span>
            <span class="text-muted d-none d-sm-none d-md-block d-lg-block d-xl-block">Your order</span>
            <span class="badge badge-pill badge-dark">
              <span>1</span>
            </span>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 order-md-2 mb-1">
          <ul class="list-group mb-3" style="border-radius: 0.25rem; box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);" :class="darkMode">
            <!-- Item List -->
            <div v-if="itemData != null">
              <template v-for="item in itemData">
                <li :key="item.id" class="list-group-item inherit-color d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 class="my-0">{{item.count}}x {{item.name}}</h6>
                    <small class="text-muted">No Description</small>
                  </div>
                  <span class="text-muted">{{convertToDinero(item.price)}}</span>
                </li>
              </template>
            </div>
            <div v-else>
              <li class="list-group-item inherit-color d-flex justify-content-center align-items-center lh-condensed">
                <div class="spinner-border text-primary m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </li>
            </div>
            <!-- Item List -->
            <li class="list-group-item inherit-color d-flex justify-content-between">
              <span>Total (GBP)</span>
              <strong v-if="itemData != null">{{convertToDinero(totalFromItems(this.itemData))}}</strong>
              <strong v-else>£0.00</strong>
            </li>
          </ul>
        </div>
        <div class="col-md-8 order-md-1">
          <form id="payment-form">
            <content-loader v-if="loading" :width="333" :height="97" :speed="2" primaryColor="#b0b0b0" secondaryColor="#ecebeb">
              <rect x="0" y="0" rx="7" ry="7" width="333" height="97" />
            </content-loader>
            <div id="card-container" class="m-0" v-bind:class="loading ? 'd-none' : ''"></div>
          </form>

          <button id="card-button" class="btn btn-lg w-100 btn-dark mt-3" type="button">
            <span v-if="loading" class="spinner-border"></span>
            <span v-else>
              Pay
              <strong v-if="itemData != null">{{convertToDinero(totalFromItems(this.itemData))}}</strong>
              <strong v-else>£0.00</strong>
            </span>
          </button>

          <div class="sq-wallet-divider">
            <span class="sq-wallet-divider__text">Or</span>
          </div>

          <div class="alternativePay">
            <div id="google-pay-button" class="w-100 mb-2"></div>
            <div id="apple-pay-button" class="w-100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import { getAuth } from "firebase/auth";
import { ContentLoader } from "vue-content-loader"
import { getDarkMode, darkModeStyle } from '@assets/js/darkMode';
import { loadUser } from '@assets/js/userController';
import { getRemoteConfigValue } from '@assets/js/remoteConfig';
import { createSquare, getCredentials, handleSubmission, buildPaymentRequest } from '@assets/js/square';

import Dinero from 'dinero.js'

export default {
  components: {
    ContentLoader
  },
  data() {
    return {
      totalAmount: 0,
      itemData: null,
      loading: true,
      darkModeCardStyle: { '.input-container': { borderColor: '#262626', borderRadius: '6px' }, '.message-text': { color: '#FFFFFF', }, '.message-icon': { color: '#FFFFFF', }, '.message-text.is-error': { color: '#f93154', }, '.message-icon.is-error': { color: '#f93154', }, 'input': { backgroundColor: '#ebebeb', color: '#000000', }, 'input::placeholder': { color: '#747474', }, 'input.is-error': { color: '#f93154', } },
      darkMode: darkModeStyle("bg-dark text-light", "bg-light text-dark"),
      darkModeBade: darkModeStyle("badge-dark", "badge-light"),
      darkModeBtn: darkModeStyle("btn-dark", "btn-light"),
    }
  },
  methods: {
    async userData() {
      const user = this.$store.getters.user;

      if (user == null) {
        const userData = await loadUser();
        this.$store.commit('setUser', userData);
        return userData;
      }

      return user;
    },
    async initializeSquare() {

      const USER = await this.userData();
      const ENVIROMENT = await getRemoteConfigValue('ENVIROMENT');
      const API_CREDS = await getCredentials(ENVIROMENT.asString());

      await createSquare(ENVIROMENT.asString());

      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }

      const payments = window.Square.payments(API_CREDS.APPID, API_CREDS.LOCATIONID);
      const totalAmount = this.totalFromItems(this.itemData);

      var card = null;
      var applePay = null;
      var googlePay = null;

      try {
        card = await this.initializeCard(payments);
      } catch (error) {
        console.warn(error);
      }

      try {
        applePay = await this.initializeApplePay(payments, totalAmount);
      } catch (error) {
        console.warn(error);
      }

      try {
        googlePay = await this.initializeGooglePay(payments, totalAmount);
      } catch (error) {
        console.warn(error);
      }

      $('#card-button').click(async () => {
        try {
          this.loading = true;
          console.log('Submitting payment');
          const tokenArray = await handleSubmission(totalAmount, payments, card, true, USER);
          console.log('Payment submitted');
          await this.processPayment(tokenArray, USER);
          console.log('Payment processed');
        } catch (error) {
          console.error(error);
          this.loading = false;
        } finally {
          this.loading = false;
        }
      });

      $('#apple-pay-button').click(async () => {
        try {
          this.loading = true;
          const tokenArray = await handleSubmission(totalAmount, payments, applePay);
          await this.processPayment(tokenArray, USER);
        } catch (error) {
          console.error(error);
          this.loading = false;
        } finally {
          this.loading = false;
        }
      });

      $('#google-pay-button').click(async () => {
        try {
          this.loading = true;
          const tokenArray = await handleSubmission(totalAmount, payments, googlePay);
          await this.processPayment(tokenArray, USER);
        } catch (error) {
          console.error(error);
          this.loading = false;
        } finally {
          this.loading = false;
        }
      });

      this.loading = false;
    },
    async initializeCard(payments) {
      var options = {};
      if (getDarkMode()) { options = { style: { ...this.darkModeCardStyle } } }

      const card = await payments.card(options);
      await card.attach('#card-container');

      return card;
    },
    async initializeApplePay(payments, total) {
      const paymentRequest = buildPaymentRequest(payments, total)
      const applePay = await payments.applePay(paymentRequest);

      return applePay;
    },
    async initializeGooglePay(payments, total) {
      const paymentRequest = buildPaymentRequest(payments, total)

      const googlePay = await payments.googlePay(paymentRequest);
      await googlePay.attach('#google-pay-button');

      return googlePay;
    },
    async processPayment(tokenArray, user) {

      const loadingComponent = this.$buefy.loading.open();

      const apiURL = await getRemoteConfigValue('APIurl');
      const ENVIROMENT = await getRemoteConfigValue('ENVIROMENT');
      const API_CREDS = await getCredentials(ENVIROMENT.asString());

      const app = this.$firebaseApp;
      const auth = getAuth(app)
      const authtoken = await auth.currentUser.getIdToken();


      // If API_CREDS.LOCATIONID is not a string then error has occured
      if (typeof API_CREDS.LOCATIONID !== 'string') {
        this.$buefy.toast.open({
          message: 'An error has occured. Please try again.',
          type: 'is-danger',
        });
        loadingComponent.close();
        console.error('An error has occured, location id is not a string');
        throw new Error('An error has occured, location id is not a string');
      }

      // If token is not a string then error has occured
      if (typeof tokenArray.token !== 'string') {
        this.$buefy.toast.open({
          message: 'An error has occured, please try again',
          type: 'is-danger'
        });
        loadingComponent.close();
        console.log('An error has occured, token is not a string');
        throw new Error('An error has occured, token is not a string');
      }

      // If verification token is not a string then error has occured
      if (typeof tokenArray.verificationToken !== 'string' && tokenArray.verificationToken !== null) {
        this.$buefy.toast.open({
          message: 'An error has occured, please try again',
          type: 'is-danger'
        });
        loadingComponent.close();
        console.log('An error has occured, verification token is not a string');
        throw new Error('An error has occured, verification token is not a string');
      }

      // If user is not an object then error has occured
      if (typeof user !== 'object') {
        this.$buefy.toast.open({
          message: 'An error has occured, please try again',
          type: 'is-danger'
        });
        loadingComponent.close();
        console.log('An error has occured, user is not an object');
        throw new Error('An error has occured, user is not an object');
      }

      // If user.square_customer_id does not exist then error has occured
      if (typeof user.square_customer_id !== 'string') {
        this.$buefy.toast.open({
          message: 'An error has occured, please try again',
          type: 'is-danger'
        });
        loadingComponent.close();
        console.log('An error has occured, user.square_customer_id is not a string');
        throw new Error('An error has occured, user.square_customer_id is not a string');
      }

      // If itemData is not an array then error has occured
      if (typeof this.itemData !== 'object') {
        this.$buefy.toast.open({
          message: 'An error has occured, please try again',
          type: 'is-danger'
        });
        loadingComponent.close();
        console.log('An error has occured, itemData is not an array');
        throw new Error('An error has occured, itemData is not an array');
      }


      const idempotencyKey = uuidv4();
      const lineItems = this.itemData;
      const customerId = user.square_customer_id
      const sourceId = tokenArray.token;
      const verificationToken = tokenArray.verificationToken;
      const locationId = API_CREDS.LOCATIONID;

      const payment = {
        idempotencyKey: idempotencyKey,
        lineItems: lineItems,
        customerId: customerId,
        token: sourceId,
        verificationToken: verificationToken,
        locationId: locationId
      }

      const response = await fetch(`${apiURL.asString()}/api/order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authtoken}`,
          "csrf-token": this.$cookies.get('XSRF-TOKEN'),
        },
        body: JSON.stringify(payment)
      });

      // If response is not a 200 then error has occured
      if (response.status !== 200) {
        this.$buefy.toast.open({
          message: 'An error has occured, please try again',
          type: 'is-danger'
        });
        loadingComponent.close();
        console.log('An error has occured, response is not a 200');
        throw new Error('An error has occured, response is not a 200');
      }

      const responseData = await response.json();

      // If responseData is not an object then error has occured
      if (typeof responseData !== 'object') {
        this.$buefy.toast.open({
          message: 'An error has occured, please try again',
          type: 'is-danger'
        });
        loadingComponent.close();
        console.log('An error has occured, responseData is not an object');
        throw new Error('An error has occured, responseData is not an object');
      }

      // If responseData.status doesnt equal 'success' then error has occured
      if (responseData.status !== 'success') {
        this.$buefy.toast.open({
          message: 'An error has occured, please try again',
          type: 'is-danger'
        });
        loadingComponent.close();
        console.log('An error has occured, responseData.status is not equal to success');
        throw new Error('An error has occured, responseData.status is not equal to success');
      }

      // If responseData.data.orderId is not a string then error has occured
      if (typeof responseData.data.orderId !== 'string') {
        this.$buefy.toast.open({
          message: 'An error has occured, please try again',
          type: 'is-danger'
        });
        loadingComponent.close();
        console.log('An error has occured, responseData.data.orderId is not a string');
        throw new Error('An error has occured, responseData.data.orderId is not a string');
      }

      const orderID = responseData.data.orderId;

      // Show success toast
      this.$buefy.toast.open({
        message: 'Payment successful',
        type: 'is-success'
      });

      // Clear shopping cart
      this.$shoppingCart.clearCart();

      // Redirect to order page
      this.$router.push(`/account/orders/${orderID}`);

      // Close loading component
      loadingComponent.close();
    },
    resetForm() {
      this.card.destroy();
      this.loading = true;

      setTimeout(() => {
        this.initializeSquare();
      }, 1000);
    },
    convertToDinero(price) {
      return Dinero({ amount: price, currency: "GBP" }).toFormat();
    },
    totalFromItems(items) {
      var totalCart = 0
      for (var item in items) {
        totalCart += items[item].price * items[item].count
      }
      return Number(totalCart.toFixed(2))
    },
    totalItemCount(value) {
      if (typeof value != 'object') {
        return 0;
      }

      return this.itemData.length;
    },
  },
  async mounted() {
    const itemData = this.$store.getters.getCart;
    const itemTotal = this.totalFromItems(itemData)

    this.itemData = itemData;
    this.totalAmount = itemTotal;

    if (this.totalItemCount(itemData) == 0) {
      this.$buefy.toast.open({
        type: 'is-warning',
        message: 'No items in your cart, please try adding something to your cart'
      });

      this.$router.push('/order/menu');
    }

    if (itemTotal < 100 && this.totalItemCount(itemData) > 0) {
      this.$buefy.toast.open({
        type: 'is-warning',
        message: 'Your total is less than £1, please add more items to your cart'
      });

      this.$router.push('/order/menu');
    }

    await this.initializeSquare();
  }
}
</script>